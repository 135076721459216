import "../styles/globals.css";
import { AppPropsWithLayout } from "../src/Layout/types";
// import superjson from "superjson";
// import { Decimal } from "decimal.js";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";
import { SessionProvider, signIn, useSession } from "next-auth/react";
import { useEffect } from "react";
import Spinner from "../src/CommonComponents/components/spinner";
import Head from "next/head";
import { SessionExtension } from "./api/auth/[...nextauth]";
import React from "react";
import { useRouter } from "next/router";

import * as gtag from "../src/GoogleAnalytics/gtag";

// superjson.registerCustom<Decimal, string>(
//   {
//     isApplicable: (v): v is Decimal => Decimal.isDecimal(v),
//     serialize: (v) => v.toJSON(),
//     deserialize: (v) => new Decimal(v),
//   },
//   "decimal.js"
// );

export default function App({ Component, pageProps: { session, ...pageProps } }) {
  const getLayout = Component.getLayout || ((page) => page);

  const router = useRouter();

  // google analytics
  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      if(process.env.NODE_ENV === "production")
      {
        gtag.pageview(url);
      }
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <SessionProvider session={session}>
        <ToastContainer />
        <Head key="favicon">
          <link rel="favicon" href="https://static.shopatsns.com/favicon.ico" />
        </Head>
        {Component.auth ? (
          <Auth role={Component.auth.role}>{getLayout(<Component {...pageProps} />)}</Auth>
        ) : (
          getLayout(<Component {...pageProps} />)
        )}
      </SessionProvider>
    </>
  );
}

function Auth({ children, role }) {
  const { data, status } = useSession();
  const session = data as SessionExtension;
  const loading = status === "loading";
  const hasUser = !!session?.user;

  useEffect(() => {
    if (!loading) {
      if (!hasUser && process.env.NODE_ENV == "production") {
        signIn();
      } else if (!session.user.permissions[role] && process.env.NODE_ENV == "production") {
        toast.warning("Unauthorized");
        window.location.pathname = "/";
      }
    }
  }, [loading, hasUser, role]);
  if (loading || !hasUser) {
    return <Spinner />;
  }

  return React.cloneElement(children, { session });
}
